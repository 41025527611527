import React, { useContext, useState } from 'react'
import { UserContext, LanguageContext, TRANSLATE } from "../App";
import { Panel, Divider, Slider, Row, Col, InputNumber, Radio, RadioGroup, Grid, IconButton, Whisper, Tooltip } from 'rsuite';
import 'rsuite/lib/styles/index.less';
import { DisplayRow } from '../Components/Extras'
import { Icon } from 'rsuite';

// const download = (image, { name = "DailyIntake", extension = "jpg" } = {}) => {
//   const a = document.createElement("a");
//   a.href = image;
//   a.download = createFileName(extension, name);
//   a.click();
// };

const mockUser = {
  age: '30',
  fat: '25',
  weight: '100',
  goal: '1',
  gender: 'male'
}

export const Result = () => {
  const language = useContext(LanguageContext).language
  const user = useContext(UserContext)
  const [hideControls, setHideControls] = useState(true)

  const { age, gender, weight, fat, goal } = user.user;
  const noUserData = age === "" || gender === "" || weight === "" || fat === "" || goal === "" || gender === ""
  const userData = noUserData ? mockUser : { age, gender, weight, fat, goal }

  const gradient = true;
  const componentColorClass = userData.gender === 'male' ? 'gradient-micro' : 'gradient-micro-pink'

  const getPercent = (type, training = true) => {
    const isProtein = type === 'protein'
    const isCarbs = type === 'carbs'
    const isFat = type === 'fat'

    const p = getMicronutrients('protein', userData, training);
    const c = getMicronutrients('carbs', userData, training);
    const f = getMicronutrients('fat', userData, training);

    let protein = p.includes('-') ? p.split('-')[1] : p
    let fat = f.includes('-') ? f.split('-')[1] : f
    let carbs = c.includes('-') ? c.split('-')[1] : c

    let sum = +protein + (+fat) + (+carbs);
    let percent = '? %';

    if (isProtein) percent = (protein / sum) * 100;
    if (isCarbs) percent = (carbs / sum) * 100;
    if (isFat) percent = (fat / sum) * 100;

    return percent.toFixed(1) + '%'
  }

  return (
    <div>
      <Panel className="panel" shaded style={{ width: 'fit-content' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
          {!noUserData ? <IconButton onClick={() => setHideControls(!hideControls)} icon={<Icon icon={!hideControls ? 'close' : 'sliders'} />} circle size="lg" style={{ opacity: 0.5 }} /> : <div />}
          {/* <Whisper placement="top" trigger="hover" speaker={
            <Tooltip>
              {TRANSLATE[language].tooltip}
            </Tooltip>
          }>
            <IconButton icon={<Icon icon='question-circle' />} circle size="lg" style={{ opacity: 0.5 }} />
          </Whisper> */}
          {noUserData &&  <>
           <p style={{fontSize: '10px', opacity: 0.5}}> {TRANSLATE[language].tooltip}</p>
           </>
           }
        </div>
        <Controls
          componentColorClass={componentColorClass}
          userData={userData}
          user={user}
          hideControls={hideControls}
        />
        <Grid style={{ width: 'auto' }} >
          <div style={{ display: 'inline-flex', marginBottom: 20 }}>
            <h2 className="colorise-text">
              {TRANSLATE[language].trainingDay}
            </h2>
            <h2 className="colorise-text" style={{ opacity: 0.5, marginLeft: 10 }}>~{getMicronutrients('kcal', userData)} {TRANSLATE[language].kkcal}</h2>
          </div>
          <br />
          <Row>
            <Col xs={8} sm={8} md={8} lg={8}>
              <DisplayRow color="rgba(0, 68, 251, 0.3)" title={TRANSLATE[language].protein} amount={getMicronutrients('protein', userData)} subtitle={getPercent('protein')} type="protein" gradient={gradient} colorClass={componentColorClass} />
            </Col>
            <Col xs={8} sm={8} md={8} lg={8}>
              <DisplayRow color="rgba(0, 68, 251, 0.3)" title={TRANSLATE[language].fat} amount={getMicronutrients('fat', userData)} subtitle={getPercent('fat')} type="fat" gradient={gradient} colorClass={componentColorClass} />
            </Col>
            <Col xs={8} sm={8} md={8} lg={8}>
              <DisplayRow color="rgba(0, 68, 251, 0.3)" title={TRANSLATE[language].carbs} amount={getMicronutrients('carbs', userData)} subtitle={getPercent('carbs')} type="carbs" gradient={gradient} colorClass={componentColorClass} />
            </Col>
          </Row>
          <Divider />
          <div style={{ display: 'inline-flex', marginBottom: 20 }}>
            <h2 className="colorise-text">
              {TRANSLATE[language].chillDay}
            </h2>
            <h2 className="colorise-text" style={{ opacity: 0.5, marginLeft: 10 }}>~{getMicronutrients('kcal', userData, false)}  {TRANSLATE[language].kkcal}</h2>
          </div>
          <br />
          <Row>
            <Col xs={8} sm={8} md={8} lg={8}>
              <DisplayRow color="rgba(0, 68, 251, 0.3)" title={TRANSLATE[language].protein} amount={getMicronutrients('protein', userData, false)} subtitle={getPercent('protein', false)} type="protein" gradient={gradient} colorClass={componentColorClass} />
            </Col>
            <Col xs={8} sm={8} md={8} lg={8}>
              <DisplayRow color="rgba(0, 68, 251, 0.3)" title={TRANSLATE[language].fat} amount={getMicronutrients('fat', userData, false)} subtitle={getPercent('fat', false)} type="fat" gradient={gradient} colorClass={componentColorClass} />
            </Col>
            <Col xs={8} sm={8} md={8} lg={8}>
              <DisplayRow color="rgba(0, 68, 251, 0.3)" title={TRANSLATE[language].carbs} amount={getMicronutrients('carbs', userData, false)} subtitle={getPercent('carbs', false)} type="carbs" gradient={gradient} colorClass={componentColorClass} />
            </Col>
          </Row>
        </Grid>
        {/* {noUserData && <Button color="blue" style={{ marginTop: '50px' }} className={componentColorClass} appearance="primary" block onClick={getImage}>
          <Icon icon="camera" /> Save as image
        </Button>} */}
      </Panel>
    </div>
  )
}

const Controls = ({ componentColorClass, userData, user, hideControls }) => {
  if (hideControls) return <></>;
  return (
    <div style={{ marginBottom: '50px' }}>
      <Row>
        <h5>Weight</h5>
        <Col md={20} lg={20} xs={15} sm={15} >
          <Slider
            max={200}
            progress
            style={{ marginTop: 16 }}
            value={+userData.weight}
            onChange={value => {
              user.setUser({ ...userData, weight: value });
            }}
          />
        </Col>
        <Col md={4} lg={4} xs={9} sm={9} >
          <InputNumber
            min={0}
            max={200}
            value={+userData.weight}
            onChange={value => {
              user.setUser({ ...userData, weight: value });
            }}
          />
        </Col>
      </Row>
      <Row>
        <h5>Fat</h5>
        <Col md={20} lg={20} xs={15} sm={15}>
          <Slider
            max={100}
            progress
            style={{ marginTop: 16 }}
            value={+userData.fat}
            onChange={value => {
              user.setUser({ ...userData, fat: value });
            }}
          />
        </Col>
        <Col md={4} lg={4} xs={9} sm={9}>
          <InputNumber
            min={0}
            max={100}
            value={+userData.fat}
            onChange={value => {
              user.setUser({ ...userData, fat: value });
            }}
          />
        </Col>
      </Row>
      <Row>
        <h5>Goal</h5>
        <Col>
          <RadioGroup value={userData.goal} onChange={value => user.setUser({ ...userData, goal: value })} inline>
            <Radio value="1">1: Keep stable weight</Radio>
            <Radio value="2">2: Burn fat</Radio>
            <Radio value="3">3: Gain muscle without fat increase</Radio>
          </RadioGroup>
        </Col>
      </Row>
    </div>
  );
}

const getMicronutrients = (type, person, training = true) => {
  const { goal, weight, fat, gender } = person;
  const fatKcal = 9;
  const carbsKcal = 4;
  const proteinKcal = 4;
  const isSameRange = getCarbs(goal, weight, fat, gender, training, 'min') === getCarbs(goal, weight, fat, gender, training, 'max')
  switch (type) {
    case 'protein':
      return getProteins(goal, weight, fat, gender, training).toFixed(2);
    case 'carbs':
      return isSameRange ? getCarbs(goal, weight, fat, gender, training).toFixed(2) : `${getCarbs(goal, weight, fat, gender, training, 'min').toFixed(2)} - ${getCarbs(goal, weight, fat, gender, training, 'max').toFixed(2)}`
    case 'fat':
      return getFats(goal, weight, fat, gender, training).toFixed(2);
    case 'kcal':
      const min = (fatKcal * getFats(goal, weight, fat, gender, training)) + (carbsKcal * getCarbs(goal, weight, fat, gender, training, 'min')) + (proteinKcal * getProteins(goal, weight, fat, gender, training));
      const max = (fatKcal * getFats(goal, weight, fat, gender, training)) + (carbsKcal * getCarbs(goal, weight, fat, gender, training, 'max')) + (proteinKcal * getProteins(goal, weight, fat, gender, training));
      return isSameRange ? min.toFixed(2) : `${min.toFixed(2)} - ${max.toFixed(2)}`
    default:
      break;
  }
}

const getProteins = (goal, weight, fat = 0, gender = "male", training = true) => {
  const weightFat = +weight * (fat / 100)
  weight = +weight - (weightFat)
  switch (goal) {
    case "1":
      if (training) return weight * 2
      return weight * 1.5
    case "2":
      if (gender === "male") {
        if (+fat >= 25) {
          if (training) return +weight * 1.5
          return weight * 1
        } else {
          if (training) return weight * 2
          return weight * 1.5
        }

      } else {
        if (fat >= 30) {
          if (training) return weight * 1.5
          return weight * 1
        } else {
          if (training) return weight * 2
          return weight * 1.5
        }
      }
    case "3":
      if (training) return weight * 2.2
      return weight * 1.8

    default:
      break;
  }
}

const getCarbs = (goal, weight, fat = 0, gender = "male", training = true, range = 'min') => {
  const weightFat = +weight * (fat / 100)
  weight = +weight - (weightFat)
  switch (goal) {
    case "1":
      if (training && range === 'min') return (weight * 3)
      if (training && range === 'max') return (weight * 4)
      return (weight * 2)
    case "2":
      if (training) return (weight * 2)
      if (range === 'min') return (weight * 1)
      if (range === 'max') return (weight * 1.5)
    case "3":
      if (training) {
        if (range === 'min') return (weight * 4)
        if (range === 'max') return (weight * 5)
      } else {
        if (range === 'min') return (weight * 3)
        if (range === 'max') return (weight * 4)
      }
    default:
      break;
  }
}

const getFats = (goal, weight, fat = 0, gender = "male", training = true) => {
  const weightFat = +weight * (fat / 100)
  weight = +weight - (weightFat)
  switch (goal) {
    case "1":
      if (training) return (weight * 0.8)
      return (weight * 0.6)
    case "2":
      if (training) return (weight * 0.8)
      return (weight * 0.6)
    case "3":
      if (training) return (weight * 1)
      return (weight * 0.8)

    default:
      break;
  }
}

//goal 1
// P:1.5-2
// C: 3-4 => 2
// F: 0.6-08

//goal 2
// P:1.5-2 if Fat M >25% F>30%  1-1.5
// C: 2 => 1-1.5
// F: 0.6-08


//goal 3
// P:1.8-2.2
// C: 4-5 => 3-4
// F: 08-1

// P C = 4  KKAL F = 9 KKAL
