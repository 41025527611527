import React, { useContext, useState } from 'react'
import { Button, Modal, Form, FormGroup, FormControl, HelpBlock, ControlLabel, InputNumber, Radio, RadioGroup } from 'rsuite';
import { UserContext } from "../App";
import { LanguageContext, TRANSLATE } from "../App";

const INITIAL = {
  age: '',
  gender: '',
  weight: '',
  fat: '',
  goal: ''
}
export const Questions = ({ button }) => {
  const language = useContext(LanguageContext).language
  const { kg, required } = TRANSLATE[language];
  const {
    title,
    age,
    gender,
    male,
    female,
    weight,
    fat,
    goal,
    goal1,
    goal2,
    goal3,
  } = TRANSLATE[language].FORM;

  const user = useContext(UserContext)

  const [formValue, setFormValue] = useState(INITIAL);
  const [show, setShow] = useState(false);

  const close = () => setShow(false)
  const open = () => setShow(true)
  const handleChange = (value) => {
    setFormValue(value)
    user.setUser(value)
  }

  return (
    <div>
      <Modal show={show} onHide={close} size="xs">
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            fluid
            onChange={handleChange}
            formValue={formValue}
          >
            <FormGroup>
              <ControlLabel>{age}</ControlLabel>
              <FormControl name="age" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>{gender}</ControlLabel>
              <FormControl accepter={RadioGroup} name="gender">
                <RadioGroup value={formValue.gender} onChange={value => handleChange({ ...formValue, gender: value })} inline>
                  <Radio value="male">{male}</Radio>
                  <Radio value="female">{female}</Radio>
                </RadioGroup>
              </FormControl>
            </FormGroup>
            <FormGroup>
              <ControlLabel>{weight}  ({kg})</ControlLabel>
              <FormControl accepter={InputNumber} name="weight">
                <InputNumber />
              </FormControl>
              <HelpBlock>{required}</HelpBlock>
            </FormGroup>
            <FormGroup>
              <ControlLabel>{fat} (%)</ControlLabel>
              <FormControl name="fat" />
              <HelpBlock>{required}</HelpBlock>
            </FormGroup>
            <FormGroup>
              <ControlLabel>{goal}</ControlLabel>
              <FormControl accepter={RadioGroup} name="gender">
                <RadioGroup value={formValue.goal} onChange={value => handleChange({ ...formValue, goal: value })} inline>
                  <Radio value="1">1: {goal1}</Radio>
                  <Radio value="2">2: {goal2}</Radio>
                  <Radio value="3">3: {goal3}</Radio>
                </RadioGroup>
              </FormControl>
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={close} appearance="primary">
            Confirm
            </Button>
          <Button onClick={close} appearance="subtle">
            Cancel
            </Button>
        </Modal.Footer>
      </Modal>
      <div onClick={open}>
        {button}
      </div>
    </div>
  )
}
