import './App.css';
import 'rsuite/lib/styles/index.less';
import React, { useState, useContext } from 'react'

import { Nav, Container, Header, Navbar, Button, Content, Row, Col, Divider, Grid } from 'rsuite';
import { Result } from './Views/Result'
import apple from './img/apple.png'
import { isMobile } from "react-device-detect";
import { Questions } from './Views/Questions'
import { Explanation } from './Views/Explanation'
import { EmojiProvider, Emoji } from 'react-apple-emojis'
import emojiData from 'react-apple-emojis/src/data.json'

const Text = (props) => !isMobile ? <h1>{props.children}</h1> : <h3>{props.children}</h3>

const WavyDivider = () => (
  <div className="custom-shape-divider-bottom-1623092325">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
      <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
    </svg>
  </div>
)

const Language = () => {
  const context = useContext(LanguageContext)
  const condition = context.language === 'RUS';
  const flag = condition ? 'flag-russia' : 'flag-united-kingdom'
  const text = condition ? 'RUS' : 'ENG'
  const flagEmoji = (
    <EmojiProvider data={{ ...emojiData, ...customEmoji }}>
      <Emoji name={flag} width={32} />
    </EmojiProvider>
  );

  return (
    <div onClick={() => context.setLanguage(condition ? 'ENG' : 'RUS')}>
      {flagEmoji}
      {text}
    </div>

  )
}


const Instance = ({ language }) => {
  const [show, setShow] = useState(false)
  const openExplanation = () => setShow(true)
  const hideExplanation = () => setShow(false)


  return (<div className="navbar-page">
    <Container>
      <Header>
        <Navbar appearance="inverse" className="gradient">
          <Navbar.Body>
            <Nav>
              <Nav.Item>FIXEAT</Nav.Item>
            </Nav>
            <Nav pullRight>
              <Nav.Item><Language /></Nav.Item>
            </Nav>
          </Navbar.Body>
        </Navbar>
      </Header>
      <section className="gradient center" style={{ height: '100vh', padding: 40, position: 'relative' }}>
        <Explanation show={show} hideExplanation={hideExplanation} />
        <Grid fluid style={{ width: '100%' }}>
          <Row>
            <Col style={{ textTransform: 'uppercase' }} xs={24} sm={24} md={24} lg={12}>
              <Text>{TRANSLATE[language].howMuch}</Text>
              <Text>{TRANSLATE[language].youNeedToEat}</Text>
              <Text>{TRANSLATE[language].toReachYourGoal} </Text>
              <Divider />
              <div style={{ marginTop: '30px', display: 'inline-flex' }}>
                <Questions button={<Button className={`button-white ${!isMobile ? 'with-padding' : ''}`} size='lg' appearance="default">{TRANSLATE[language].getStarted}</Button>}
                />
                <Button onClick={openExplanation} className={`button-invert ${!isMobile ? 'with-padding' : ''}`} size='lg' appearance="ghost">{TRANSLATE[language].howItWorks}</Button>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <img className={isMobile ? 'apple-img-mobile' : 'apple-img'} alt="img" src={apple}></img>
              <div className="apple-shadow"></div>
            </Col>
          </Row>
        </Grid>
        <WavyDivider />
      </section>
      <Content className="section-aligned center">
        <Result />
      </Content>
    </Container>
  </div>)
}

export const UserContext = React.createContext({});
export const LanguageContext = React.createContext({});

function App() {
  const InitialUser = {
    age: '',
    gender: '',
    weight: '',
    fat: '',
    goal: ''
  }
  const InitialLanguage = 'RUS';
  const [user, setUser] = useState(InitialUser)
  const [language, setLanguage] = useState(InitialLanguage)


  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      <UserContext.Provider value={{ user, setUser }}>
        <Instance language={language} />
      </UserContext.Provider>
    </LanguageContext.Provider>
  );
}

export default App;


export const TRANSLATE = {
  RUS: {
    howMuch: 'сколько',
    youNeedToEat: 'вам нужно есть',
    toReachYourGoal: 'чтобы достичь  цели',
    getStarted: 'НАЧАТЬ',
    howItWorks: 'КАК ЭТО РАБОТАЕТ',
    tooltip: 'Нажмите "НАЧАТЬ", чтобы подсчитать данные по вам',
    trainingDay: 'C тренировкой',
    chillDay: 'День отдыха',
    kkcal: 'ккал',
    protein: 'Белки',
    fat: 'Жиры',
    carbs: 'Углеводы',
    gramms: 'г',
    kg: 'кг',
    required: '',
    FORM: {
      title: 'Введи данные для подсчета БЖУ и ккал',
      age: 'Возраст',
      gender: 'Пол',
      male: 'М.',
      female: 'Ж.',
      weight: 'Вес',
      fat: 'Процент жира',
      goal: 'Цель',
      goal1: 'Поддержание веса на фоне тренировок',
      goal2: 'Максимальное сжигание подкожного жира',
      goal3: 'Увеличение мышечной массы без жировой прослойки',
    }
  },

  ENG: {
    howMuch: 'how much',
    youNeedToEat: 'you need to eat',
    toReachYourGoal: 'to reach your goal',
    getStarted: 'GET STARTED',
    howItWorks: 'HOW IT WORKS',
    tooltip: 'Click "Get Started" to calculate your daily values',
    trainingDay: 'Training Day',
    chillDay: 'Chill Day',
    kkcal: 'kkcal',
    protein: 'Protein',
    fat: 'Fat',
    carbs: 'Carbs',
    gramms: '.',
    kg: 'kg',
    required: 'required',
    FORM: {
      title: 'Calculate your calories and macronutrients',
      age: 'Age',
      gender: 'Gender',
      male: 'male',
      female: 'female',
      weight: 'weight',
      fat: 'Fat',
      goal: 'Goal',
      goal1: 'Keep stable weight',
      goal2: 'Burn fat',
      goal3: 'Muscle gain without fat',
    }
  }
}

const style = 'apple'
export const customEmoji = {
  "flag-russia": "https://emojicdn.elk.sh/🇷🇺",
  "flag-united-kingdom": "https://emojicdn.elk.sh/🇬🇧",
  "flexed-biceps-light-skin-tone": "https://emojicdn.elk.sh/💪",
  "avocado": "https://emojicdn.elk.sh/🥑",
  "cooked-rice": "https://emojicdn.elk.sh/🍚",
}