import React, { useContext } from 'react'
import { Modal, Button, Placeholder, List } from 'rsuite';
import { LanguageContext, TRANSLATE } from "../App";

export function Explanation({ show, hideExplanation }) {
  const language = useContext(LanguageContext).language
  const { protein, fat, carbs, gramms, chillDay } = TRANSLATE[language];
  const {
    weight,
    goal1,
    goal2,
    goal3,
  } = TRANSLATE[language].FORM;

  return (
    <div>
      <Modal show={show} onHide={hideExplanation}>
        <Modal.Body>
          <h4>{goal1}</h4>
          <List>
            <List.Item>
              {protein}: 1.5-2 {gramms}. x {weight}
            </List.Item>
            <List.Item>
              {carbs}: 3-4 {gramms}. x {weight} ({chillDay}: 2 {gramms}. x {weight})
            </List.Item>
            <List.Item>
              {fat}: 0.6-0.8 {gramms}. x {weight}
            </List.Item>
          </List>
          <h4>{goal2}</h4>
          <List>
            <List.Item>
              {protein}: 1.5-2 {gramms}. x {weight}
            </List.Item>
            <List.Item>
              {carbs}: 2 {gramms}. x {weight}  ({chillDay}: 1-1.5 {gramms}. x {weight})
            </List.Item>
            <List.Item>
              {fat}: 0.6-0.8 {gramms}. x {weight}
            </List.Item>
          </List>
          <h4>{goal3}</h4>
          <List>
            <List.Item>
              {protein}: 1.8-2.2 {gramms}. x {weight}
            </List.Item>
            <List.Item>
              {carbs}: 4-5 {gramms}. x {weight} {chillDay}: 3-4 {gramms}. x {weight})
            </List.Item>
            <List.Item>
              {fat}: 0.8-1 {gramms}. x {weight}
            </List.Item>
          </List>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={hideExplanation} appearance="primary">
            OK
            </Button>
        </Modal.Footer>
      </Modal>


    </div>
  )
}

//goal 1
// P:1.5-2
// C: 3-4 => 2
// F: 0.6-08

//goal 2
// P:1.5-2 if Fat M >25% F>30%  1-1.5
// C: 2 => 1-1.5
// F: 0.6-08


//goal 3
// P:1.8-2.2
// C: 4-5 => 3-4
// F: 08-1

// P C = 4  KKAL F = 9 KKAL