import React, { useContext } from 'react'
import { Badge, Tag } from 'rsuite';
import { EmojiProvider, Emoji } from 'react-apple-emojis'
import emojiData from 'react-apple-emojis/src/data.json'


import { LanguageContext, TRANSLATE, customEmoji } from "../App";

export const DisplayRow = ({ color, title, amount, subtitle, type, gradient, colorClass, myRef }) => {
  const language = useContext(LanguageContext).language

  const spacing = 25;
  const mid = 500;
  const bold = 600;
  const light = 300;
  const height = 15;

  const emoji = {
    protein: 'flexed-biceps-light-skin-tone',
    fat: 'avocado',
    carbs: 'cooked-rice'
  }
  const colorStyle = !gradient ? `${color.replace(/[^,]+(?=\))/, '1')}` : 'white';
  return (
    <div className={`${colorClass} center data-square`} style={{ background: gradient ? '' : color }}>
      <EmojiProvider data={{ ...emojiData, ...customEmoji }}>
        <Emoji name={emoji[type]} width={32} className="emoji" />
      </EmojiProvider>
      <h4 className="title" style={{ fontWeight: bold, color: colorStyle }}>{title}</h4>
      <h4 className="amount" style={{ fontWeight: light, color: colorStyle }}>{amount} {TRANSLATE[language].gramms}.</h4>
      <h4 className="subtitile" style={{ fontWeight: light, color: colorStyle }}>{subtitle}</h4>
    </div >
  );
}